import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"
import Analytics from "../components/utils/analytics"

export default class PartnersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      email: "",
      message: "",
      form_interest: "join-partners",
      submitButtonDisabled: false,
      submitted: false,
      asyncRequests: [],
      form: "",
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    let {
      email,
      first_name,
      last_name,
      company,
      phone,
      form_interest,
    } = this.state
    this.setState({ form: e.target })

    this.setState({ submitButtonDisabled: true })
    Analytics.identifyUser(
      email,
      {
        id: email,
        email: email,
        first_name: first_name,
        last_name: last_name,
        company: company,
        phone: phone,
        interestedProduct: form_interest,
      },
      () => {
        console.log(`opt in: ${this.state.asyncRequests}`)
        this._checkAllAsyncRequestsCompleted("identifyUser")
      }
    )

    window.analytics.track(
      "Opted-in",
      {
        form_name: this._cleanUpPathname(),
        opt_in_page: this._cleanUpPathname(),
      },
      () => {
        console.log(`opt in: ${this.state.asyncRequests}`)
        this._checkAllAsyncRequestsCompleted("trackOptedIn")
      }
    )

    if (process.env.NODE_ENV === "production") {
      Analytics.submitToSlack(this.state).then(() => {
        this._checkAllAsyncRequestsCompleted("slackSubmit")
      })
    }
  }

  _checkAllAsyncRequestsCompleted(name) {
    let { asyncRequests } = this.state
    asyncRequests.push(name)
    let numAsyncReq
    if (process.env.NODE_ENV === "production") {
      numAsyncReq = 3
    } else {
      numAsyncReq = 2
    }
    if (asyncRequests.length === numAsyncReq) {
      asyncRequests = []
      this.setState({ submitted: true })
    }
  }

  _cleanUpPathname() {
    var path = window.location.pathname.replace(/\.html/, "").replace(/\//, "")
    if (path === "") {
      return "home"
    } else {
      return path
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "Partners")
  }

  render() {
    const metadata = {
      title: "Partnership and Collaboration | Advanx Health",
      description:
        "At Advanx Health, we're all about empowering individuals to make the best lifestyle choices based on their DNA. Join our growing ecosystem of partners to make customer personalisation a part of your business.",
      image:
        "https://www.advanxhealth.com/img/logo-horizontal-blue-wording.png",
      url: "https://www.advanxhealth.com/partners",
    }
    let submittedMessage
    if (this.state.submitted) {
      submittedMessage = (
        <p className="m-0">
          <span role="img" aria-label="yay">
            🎉
          </span>{" "}
          Thanks, we will get in touch shortly.
        </p>
      )
    } else {
      submittedMessage = <br />
    }

    return (
      <Layout>
        <ProgressBar />
        <HeadMeta metadata={metadata} />
        <section
          className="bg-dark"
          style={{
            backgroundImage: `url('/img/partnership.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "54vh",
          }}
        >
          <div className="container">
            <div className="text-center text-white">
              <h1 className="text-center">Community & Research Partners</h1>
              <p className="text-center mt-5">
                Create A Healthy Ecosystem Together
              </p>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue-green p-0 py-md-5">
          <div className="container">
            <div className="row text-white align-items-center">
              <div className="col-md-6 p-md-5 p-0 order-md-2">
                <img
                  src={withPrefix("/img/partners/partners-1.jpg")}
                  className="img-fluid partner-image"
                  alt="Partners"
                />
              </div>
              <div className="col-md-6 p-md-5 p-3 order-md-1">
                <p>
                  At Advanx Health, we're all about empowering individuals to
                  live a healthy lifestyle based on their DNA. We believe that
                  when individuals get deeper understanding into their genetics,
                  they will play a more active role in their health and
                  wellness.
                </p>
              </div>
              <div className="col-md-6 p-md-5 p-0 order-md-3">
                <img
                  src={withPrefix("/img/partners/partners-2.jpg")}
                  className="img-fluid partner-image"
                  alt="Partners"
                />
              </div>
              <div className="col-md-6 p-md-5 p-3 order-md-4">
                <p>
                  As individuals become more proactive in managing their
                  lifestyle, that's where the ecosystem comes in. We envision an
                  ecosystem of health and wellness partners that could provide
                  valuable services to these individuals, thus guiding them
                  towards a healthier and more personalised lifestyle.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light">
          <div className="container">
            <h2 className="text-center">
              Join Our Growing Ecosystem of Partners
            </h2>
            <h3 className="text-center mt-4 text-primary">
              Community Partners
            </h3>
            <div className="row align-items-center justify-content-center">
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/acare-pharmacy.png")}
                  className="img-fluid"
                  alt="Acare Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/ampmpharmacy.png")}
                  className="img-fluid"
                  alt="AMPM Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/alpro-plus-pharmacy.png")}
                  className="img-fluid"
                  alt="Ascen Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/city_wellness_pharmacy.png")}
                  className="img-fluid"
                  alt="City Wellness Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/cookitup-kitchen.png")}
                  className="img-fluid"
                  alt="Cookitup Kitchen"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/df-pharmacy.png")}
                  className="img-fluid"
                  alt="DF Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/doc2uslogo.png")}
                  className="img-fluid"
                  alt="Doc2Us"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/tigas-pharmacy.png")}
                  className="img-fluid"
                  alt="Tigas Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/unicare-pharmacy.png")}
                  className="img-fluid"
                  alt="Unicare Pharmacy"
                />
              </div>
              <div className="col-4 col-md-2">
                <img
                  src={withPrefix("/img/partners/zenxin.png")}
                  className="img-fluid"
                  alt="Zenxin"
                />
              </div>
            </div>
            <h3 className="text-center mt-4 text-primary">Research Partners</h3>
            <div className="row">
              <div className="col-6 offset-3 col-md-4 offset-md-4">
                <img
                  src={withPrefix("/img/partners/utm.png")}
                  className="img-fluid"
                  alt="UTM"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-5 offset-md-1">
                <Link
                  to="#"
                  className="btn btn-lg btn-block btn-pink px-0 my-3"
                  style={{
                    borderRadius: "0.3rem",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>
                    Discover Our Science
                  </span>
                </Link>
              </div>
              <div className="col-md-5">
                <Link
                  to={withPrefix("/advanx-lab")}
                  className="btn btn-lg btn-block btn-primary px-0 my-3"
                  style={{
                    borderRadius: "0.3rem",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>
                    Discover Advanx Lab Services
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section id="join" className="bg-gradient-pink-orange">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="form-holder">
                  <h2>Interested in partnering?</h2>
                  <p>
                    Contact us and we'll reach out to you to schedule a
                    conversation.
                  </p>
                  <form
                    className="form text-left"
                    id="lead-form"
                    name="partner-form"
                    action=""
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-row form-group">
                      <div className="col">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          id="first_name"
                          required
                          value={this.state.first_name}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          id="last_name"
                          required
                          value={this.state.last_name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-row form-group">
                      <div className="col">
                        <label htmlFor="company">Company</label>
                        <input
                          className="form-control"
                          type="text"
                          name="company"
                          id="company"
                          required
                          value={this.state.company}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="phone">Phone</label>
                        <input
                          className="form-control"
                          type="tel"
                          inputMode="numeric"
                          name="phone"
                          id="phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="3"
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary submit"
                        disabled={this.state.submitButtonDisabled}
                      >
                        Submit
                      </button>
                      {submittedMessage}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
